module.exports = {
    defaultZone: 'ru',
    excludedPropsFromGeo: [
        'jiraIssue',
        'uid',
        'widgetGeo',
        '__mocksource',
        'dateTo',
        'dateFrom',
        'widgetReused',
        'widgetDisabled',
        'widgetComment'
    ]
};
